@keyframes twinkle {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.3; }
  }
  
  @keyframes float-ship {
    0% {
      transform: translateX(0);
      opacity: 0;
    }
    5% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      transform: translateX(calc(100vw + 100px));
      opacity: 0;
    }
  }
  
  .animate-twinkle {
    animation: twinkle var(--tw-animation-duration, 4s) ease-in-out infinite;
  }
  
  .animate-float-ship {
    animation: float-ship 20s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  }
  
  @keyframes float {
    0%, 100% {
      transform: translateY(0) translateX(0);
    }
    50% {
      transform: translateY(-20px) translateX(10px);
    }
  }
  
  .animate-float {
    animation: float 15s ease-in-out infinite;
  }
  
  .transition-theme {
    transition: all 1s ease-in-out;
  }
  
  @keyframes float-slow {
    0%, 100% {
      transform: translateY(0) translateX(0);
    }
    50% {
      transform: translateY(-10px) translateX(10px);
    }
  }
  
  .animate-float-slow {
    animation: float-slow 8s ease-in-out infinite;
  }
  
  .-skew-x-15 {
    transform: skewX(-15deg);
  }
  
  @keyframes float-ship-ltr {
    0% {
      transform: translateX(0) translateY(0);
      opacity: 0;
    }
    5% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      transform: translateX(calc(100vw + 100px)) translateY(10px);
      opacity: 0;
    }
  }
  
  @keyframes float-ship-rtl {
    0% {
      transform: translateX(0) translateY(0);
      opacity: 0;
    }
    5% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      transform: translateX(calc(-100vw - 100px)) translateY(10px);
      opacity: 0;
    }
  }
  
  @keyframes window-flicker {
    0%, 100% {
      opacity: 0.3;
    }
    50% {
      opacity: 0.6;
    }
  }
  
  .animate-window-flicker {
    animation: window-flicker 3s ease-in-out infinite;
  }
  
  @keyframes window-glow {
    0%, 100% {
      opacity: 0.8;
      filter: brightness(1);
    }
    50% {
      opacity: 1;
      filter: brightness(1.2);
    }
  }
  
  .animate-window-glow {
    animation: window-glow 3s ease-in-out infinite;
  }
  
  @keyframes vertical-light {
    0%, 100% {
      opacity: 0.3;
    }
    50% {
      opacity: 0.6;
    }
  }
  
  .animate-vertical-light {
    animation: vertical-light 4s ease-in-out infinite;
  }
  
  .login-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background: linear-gradient(135deg, #1e293b 0%, #0f172a 100%);
  }
  
  .content-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-height: 100vh;
    z-index: 50;
    pointer-events: auto;
  }
  
  .login-highlight {
    position: relative;
  }
  
  .login-highlight::before {
    content: '';
    position: absolute;
    inset: -50px;
    background: radial-gradient(
      circle at center,
      rgba(99, 102, 241, 0.15),
      transparent 70%
    );
    z-index: -1;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.4s ease;
  }
  
  .login-highlight:hover::before {
    opacity: 1;
  }
  
  .will-change-input {
    will-change: contents;
    transform: translateZ(0);
    isolation: isolate;
    contain: content;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .animate-float,
    .animate-float-slow,
    .animate-window-glow,
    .animate-window-flicker,
    .animate-vertical-light {
      animation: none;
    }
  }
  
  .absolute {
    transform: translateZ(0);
    will-change: transform;
  }
  
  [class*="star-"] {
    will-change: opacity;
    transform: translateZ(0);
  }
  
  /* Optimize paint operations */
  .login-container * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  /* Reduce paint area */
  .content-wrapper {
    contain: layout style paint;
  }
  
  /* Optimize background animations */
  @media (pointer: fine) {
    .animate-float,
    .animate-float-slow {
      animation-play-state: paused;
    }
  
    .content-wrapper:hover .animate-float,
    .content-wrapper:hover .animate-float-slow {
      animation-play-state: running;
    }
  }
  
  /* Reduce animation work during input */
  input:focus ~ .animate-float,
  input:focus ~ .animate-float-slow,
  input:focus ~ .animate-window-glow {
    animation-play-state: paused;
  }
  
  /* Add new bubble animation */
  @keyframes float-bubble {
    0%, 100% {
      transform: translateY(0) translateX(0) scale(1);
    }
    50% {
      transform: translateY(-20px) translateX(10px) scale(1.1);
    }
  }
  
  .bubble {
    position: absolute;
    border-radius: 50%;
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.05)
    );
    backdrop-filter: blur(5px);
    animation: float-bubble 8s ease-in-out infinite;
    pointer-events: none;
  }
  
  /* Enhanced login form container with new styling */
  .login-form-container {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(12px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 24px;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;
    overflow: hidden;
  }
  
  .login-form-container:hover {
    transform: translateY(-5px) scale(1.01);
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.3);
    border-color: rgba(99, 102, 241, 0.5);
  }
  
  /* Enhanced input styling */
  .login-input {
    background: rgba(255, 255, 255, 0.08);
    border: 1px solid rgba(255, 255, 255, 0.15);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    backdrop-filter: blur(8px);
  }
  
  .login-input:focus {
    background: rgba(255, 255, 255, 0.12);
    border-color: rgba(99, 102, 241, 0.6);
    box-shadow: 0 0 0 4px rgba(99, 102, 241, 0.15);
  }
  
  /* Optimize input performance */
  .login-input,
  input[type="email"],
  input[type="password"] {
    transform: translateZ(0);
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;
    contain: content;
  }
  
  /* Pause ALL animations during input focus */
  .login-form-container:focus-within {
    /* Immediately pause ALL animations when form is being used */
    & *,
    & ~ * {
      animation-play-state: paused !important;
      transition: none !important;
    }
    
    /* Reduce visual effects during input */
    & .bubble,
    & ~ [class*="star-"],
    & ~ .animate-float,
    & ~ .animate-float-slow,
    & ~ .animate-window-glow,
    & ~ .animate-window-flicker,
    & ~ .animate-vertical-light {
      opacity: 0.1;
      transition: opacity 0.2s ease;
    }
  }
  
  /* Optimize paint operations for form container */
  .login-form-container {
    transform: translateZ(0);
    backface-visibility: hidden;
    will-change: transform;
    contain: content;
    isolation: isolate;
  }
  
  /* Reduce animation work during input */
  input:focus {
    & ~ .animate-float,
    & ~ .animate-float-slow,
    & ~ .animate-window-glow {
      animation: none !important;
    }
  }
  
  /* Add containment to wrapper */
  .content-wrapper {
    contain: layout style paint;
    isolation: isolate;
  }
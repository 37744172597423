@import '@fortawesome/fontawesome-free/css/all.min.css';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
  /* Color system */
  --primary-color: #4f46e5;
  --secondary-color: #64748b;
  --background-color: #f0f4f8;
  --border-color: #e5e7eb;
  --text-primary: #1e293b;
  --text-secondary: #4b5563;
  
  /* Shadows */
  --shadow-sm: 0 1px 3px rgba(0, 0, 0, 0.1);
  --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.1);
  
  /* Border radius */
  --radius-sm: 8px;
  --radius-md: 12px;
  
  /* Spacing */
  --space-1: 0.25rem;
  --space-2: 0.5rem;
  --space-3: 0.75rem;
  --space-4: 1rem;
  --space-5: 1.5rem;
  --space-6: 2rem;
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--text-primary);
}

/* Base component styles */
.card {
  background: white;
  border-radius: var(--radius-md);
  box-shadow: var(--shadow-sm);
  padding: var(--space-4);
}

.button {
  padding: var(--space-2) var(--space-4);
  border-radius: var(--radius-sm);
  font-weight: 500;
  transition: transform 0.2s ease;
}

.button:hover {
  transform: translateY(-2px);
}

.button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.2);
}

/* Optimize animations */
.animated {
  will-change: transform, opacity;
  transform: translateZ(0);
  backface-visibility: hidden;
}

/* Add this to the root level to affect all elements */
* {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Only allow scrollbars for PO and Master tables */
.po-table-container::-webkit-scrollbar,
.master-table-container::-webkit-scrollbar,
.table-container::-webkit-scrollbar {
  display: block;
  width: 6px;
  height: 6px;
}

.po-table-container::-webkit-scrollbar-track,
.master-table-container::-webkit-scrollbar-track,
.table-container::-webkit-scrollbar-track {
  background: transparent;
}

.po-table-container::-webkit-scrollbar-thumb,
.master-table-container::-webkit-scrollbar-thumb,
.table-container::-webkit-scrollbar-thumb {
  background-color: rgba(203, 213, 225, 0.4);
  border-radius: 3px;
}

/* For Firefox - only show scrollbars on tables */
.po-table-container,
.master-table-container,
.table-container {
  scrollbar-width: thin;
  scrollbar-color: rgba(203, 213, 225, 0.4) transparent;
}

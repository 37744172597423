.notification-dropdown {
  position: fixed;
  top: 70px;
  right: 20px;
  width: 320px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  border: 1px solid var(--border);
  backdrop-filter: blur(8px);
}

/* Add animation for dropdown */
@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Add overlay when notifications are open */
.notification-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9998;
  backdrop-filter: blur(2px);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.875rem 1rem;
  border-bottom: 1px solid var(--border);
}

.notification-header h3 {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  font-weight: 600;
}

.clear-all-btn {
  display: flex;
  align-items: center;
  gap: 0.375rem;
  background: none;
  border: none;
  color: var(--primary);
  font-size: 0.75rem;
  cursor: pointer;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
}

.clear-all-btn:hover {
  background: var(--background);
}

.notification-list {
  max-height: 300px;
  overflow-y: auto;
}

.notification-item {
  padding: 0.75rem 1rem;
  border-bottom: 1px solid var(--border);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
}

.notification-content {
  display: flex;
  gap: 0.875rem;
  align-items: flex-start;
}

.notification-icon {
  width: 14px;
  height: 14px;
}

.notification-icon.success { color: var(--success); }
.notification-icon.warning { color: var(--warning); }
.notification-icon.info { color: var(--primary); }

.notification-text {
  flex: 1;
}

.notification-message {
  display: block;
  font-size: 0.875rem;
  color: var(--text);
  margin-bottom: 0.25rem;
}

.notification-time {
  display: flex;
  align-items: center;
  gap: 0.375rem;
  font-size: 0.75rem;
  color: var(--text-light);
}

.remove-notification {
  background: none;
  border: none;
  color: var(--text-light);
  cursor: pointer;
  padding: 0.25rem;
  border-radius: 0.25rem;
}

.remove-notification:hover {
  background: var(--background);
  color: var(--danger);
}

.no-notifications {
  padding: 2rem;
  text-align: center;
  color: var(--text-light);
  font-size: 0.875rem;
}

/* Notification button and icon adjustments */
.notification-btn {
  position: relative;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
}

.notification-btn svg {
  width: 16px;
  height: 16px;
}

/* Badge adjustments */
.notification-badge {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 18px;
  height: 18px;
  background-color: #ef4444;
  color: white;
  font-size: 0.75rem;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  border: 2px solid white;
  pointer-events: none;
  user-select: none;
  transform: scale(0);
  transition: transform 0.2s ease;
}

.notification-badge.show {
  transform: scale(1);
}

/* Settings button adjustments */
.settings-btn {
  padding: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.settings-btn svg {
  width: 16px;
  height: 16px;
}

/* Notification dropdown positioning */
.notification-wrapper {
  position: relative;
}

/* Add dropdown arrow */
.notification-dropdown::before {
  content: '';
  position: absolute;
  top: -6px;
  right: 15px;
  width: 12px;
  height: 12px;
  background: white;
  transform: rotate(45deg);
  border-left: 1px solid var(--border);
  border-top: 1px solid var(--border);
}

/* Notification center adjustments */
.notification-center {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
}

/* Icon and badge adjustments */
.icon-with-badge {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  cursor: pointer;
}

/* Icon size standardization */
.nav-icon {
  width: 20px !important; /* Set to exactly 20px */
  height: 20px !important;
  color: #334155;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Settings button alignment */
.settings-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  cursor: pointer;
  background: none;
  border: none;
}

.settings-btn svg {
  width: 20px !important; /* Set to exactly 20px */
  height: 20px !important;
  color: #334155;
}

/* Badge adjustments for 20px icons */
.notification-badge {
  position: absolute;
  top: -2px;
  right: -2px;
  min-width: 14px; /* Adjusted for 20px icon */
  height: 14px;
  font-size: 0.65rem;
  background: var(--primary);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid white;
  z-index: 2;
  padding: 0 3px;
}

/* Remove icon */
.remove-icon {
  color: var(--text-light);
  cursor: pointer;
  padding: 0.25rem;
  border-radius: 0.25rem;
  transition: all 0.2s ease;
}

.remove-icon:hover {
  background: var(--background);
  color: var(--danger);
}

/* User profile section */
.user-profile {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.5rem 1rem;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.user-profile img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
}

.user-profile span {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--text);
}

/* Add these new styles for the enhanced profile section */
.user-profile-wrapper {
  position: relative;
}

.user-profile {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.5rem 0.75rem;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  cursor: pointer;
}

.user-profile:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.user-avatar-wrapper {
  position: relative;
}

.avatar {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid var(--primary);
  padding: 2px;
  background: white;
}

.status-indicator {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--success);
  border: 2px solid white;
  box-shadow: 0 0 0 2px rgba(34, 197, 94, 0.2);
}

.user-info {
  display: flex;
  flex-direction: column;
  min-width: 100px;
}

.user-name {
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--text);
  white-space: nowrap;
}

.user-role {
  font-size: 0.75rem;
  color: var(--text-light);
}

.profile-arrow {
  margin-left: auto;
  color: var(--text-light);
  transition: transform 0.3s ease;
}

.user-profile:hover .profile-arrow {
  transform: rotate(180deg);
}

/* Update existing notification center styles */
.notification-center {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-right: 1rem;
}

/* Glass effect for buttons */
.glass-effect {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.glass-effect:hover {
  background: rgba(255, 255, 255, 0.2);
}

/* Update notification buttons */
.icon-with-badge {
  padding: 0.5rem;
  border-radius: 10px;
  transition: all 0.3s ease;
}

.icon-with-badge:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-2px);
}

/* Settings button update */
.settings-btn {
  padding: 0.5rem;
  border-radius: 10px;
  transition: all 0.3s ease;
}

.settings-btn:hover {
  transform: translateY(-2px);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .user-info {
    display: none;
  }
  
  .user-profile {
    padding: 0.375rem;
  }
  
  .notification-center {
    margin-right: 0.5rem;
  }
}

.header-right {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0 1.5rem;
  height: 64px;
  margin-left: auto;
}

.notification-center {
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 100%;
}

.icon-button {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  color: var(--text);
}

.icon-button:hover {
  transform: translateY(-2px);
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.notification-badge {
  position: absolute;
  top: -6px;
  right: -6px;
  min-width: 20px;
  height: 20px;
  background: var(--primary);
  color: white;
  font-size: 0.75rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 6px;
  border: 2px solid white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-dropdown-wrapper {
  position: relative;
}

.profile-trigger {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.5rem 0.75rem;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
  transition: all 0.2s ease;
}

.profile-trigger:hover {
  transform: translateY(-2px);
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.avatar-container {
  position: relative;
  width: 38px;
  height: 38px;
}

.user-avatar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid var(--primary);
  padding: 2px;
  background: white;
}

.status-dot {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid white;
  transition: background-color 0.3s ease;
}

.status-dot.online {
  background: var(--success);
  box-shadow: 0 0 0 2px rgba(34, 197, 94, 0.2);
}

.status-dot.away {
  background: var(--warning);
  box-shadow: 0 0 0 2px rgba(234, 179, 8, 0.2);
}

.status-dot.offline {
  background: var(--danger);
  box-shadow: 0 0 0 2px rgba(239, 68, 68, 0.2);
}

.profile-chevron {
  transition: transform 0.2s ease;
}

.profile-chevron.rotated {
  transform: rotate(180deg);
}

.profile-dropdown {
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  width: 280px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
  animation: slideDown 0.2s ease-out;
}

.profile-header {
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.profile-header .profile-picture {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-header h4 {
  margin: 0;
  font-size: 0.9rem;
  color: var(--text);
}

.profile-header p {
  margin: 0;
  font-size: 0.8rem;
  color: var(--text-light);
}

.profile-menu {
  padding: 0.5rem;
}

.profile-menu button {
  width: 100%;
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  border: none;
  background: none;
  color: var(--text);
  font-size: 0.9rem;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.profile-menu button:hover {
  background: rgba(0, 0, 0, 0.05);
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .user-info {
    display: none;
  }
  
  .profile-trigger {
    padding: 0.375rem;
  }
  
  .header-right {
    gap: 0.75rem;
    padding-right: 1rem;
  }
}

.avatar-fallback {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--primary);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 1rem;
  border: 2px solid white;
  text-transform: uppercase;
}

.user-avatar.avatar-fallback {
  font-size: 1rem;
  border: 2px solid var(--primary);
  padding: 2px;
  background: var(--primary);
}

.profile-picture.avatar-fallback {
  font-size: 1.2rem;
  border: none;
  background: var(--primary);
}

/* Update existing avatar styles */
.user-avatar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid var(--primary);
  padding: 2px;
  background: white;
}

.profile-picture {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .notification-center {
    gap: 0.5rem;
  }

  .icon-button {
    width: 36px;
    height: 36px;
  }

  .user-profile {
    padding: 0.25rem;
  }

  .notification-dropdown {
    width: calc(100vw - 2rem);
    max-width: 320px;
    right: 1rem;
  }
}

.shadow-standard {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
              0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
:root {
    --primary-bg: #f0f4f8;
    --secondary-bg: #ffffff;
    --sidebar-bg: #1e293b;
    --text-primary: #2d3748;
    --text-secondary: #4a5568;
    --accent-color: #3498db;
    --success-color: #2ecc71;
    --warning-color: #f39c12;
    --danger-color: #e74c3c;
    --border-color: #e5e7eb;
  }
  
  body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    background-color: var(--primary-bg);
    color: var(--text-primary);
  }
  
  .logo {
    font-size: 24px;
    font-weight: 600;
    padding: 20px;
    color: white;
    text-align: center;
    border-bottom: 1px solid rgba(255,255,255,0.1);
    cursor: pointer;
  }
  
  .menu-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    cursor: pointer;
  }
  .sub-menu {
    background-color: rgba(255, 255, 255, 0.1);
    padding-left: 20px;
  }
  
  .sub-menu-item {
    padding: 8px 15px;
    font-size: 0.9em;
    cursor: pointer;
  }
  
  .sub-menu-item:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  .status-container {
    display: flex;
    gap: 5px;
  }
  
  .status-pill {
    padding: 3px 8px;
    border-radius: 12px;
    font-size: 0.8em;
    font-weight: bold;
  }
  
  .status-approved {
    background-color: #2ecc71;
    color: white;
  }
  
  .status-pending {
    background-color: #f39c12;
    color: white;
  }
  
  .menu-item i {
    margin-right: 10px;
  }
  
  .menu-item:hover {
    background-color: rgba(255,255,255,0.1);
  }
  
  .submenu {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    background-color: rgba(255,255,255,0.1);
  }
  
  .menu-item.active .submenu {
    max-height: 200px;
  }
  
  .submenu-item {
    padding: 10px 20px 10px 40px;
    font-size: 0.9em;
    cursor: pointer;
  }
  
  .submenu-item:hover {
    background-color: rgba(255,255,255,0.2);
  }
  
  .main-content {
    flex-grow: 1;
   
    transition: margin-left 0.3s ease;
  }
  
  .top-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 40px;
    background-color: var(--secondary-bg);
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    position: fixed;
    top: 0;
    left: 250px;
    right: 0;
    z-index: 900;
    transition: left 0.3s ease;
  }
  
  .nav-icons {
    display: flex;
    gap: 20px;
    margin-right: 20px;
  }
  
  .user-profile {
    display: flex;
    align-items: center;
  }
  
  .user-profile img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .user-profile span {
    font-weight: 500;
  }
  
  .user-profile img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .dashboard-content {
    padding: 16px;
    background-color: var(--primary-bg);
  }
  
  .summary-scroll-container {
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 16px;
  }
  
  .filters-container,
  .metrics-container,
  .charts-container,
  .table-container {
    background: var(--secondary-bg);
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .section-title {
    padding: 12px 16px;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .section-title h2 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
  }
  
  .section-title svg {
    color: var(--accent-color);
    font-size: 16px;
  }
  
  .metrics {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 16px;
    padding: 16px;
  }
  
  .metric-card {
    background: var(--secondary-bg);
    border-radius: 12px;
    padding: 1.5rem;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .metric-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(90deg, var(--accent-color), transparent);
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .metric-card:hover::before {
    opacity: 1;
  }
  
  .metric-sparkline {
    margin-top: 1rem;
    height: 40px;
  }
  
  .metric-value {
    font-size: 1.75rem;
    font-weight: 600;
    color: var(--text-primary);
    margin: 0.5rem 0;
    background: none;
    -webkit-text-fill-color: initial;
  }
  
  .animate-in {
    animation: slideIn 0.6s ease-out forwards;
    opacity: 0;
    transform: translateY(20px);
  }
  
  @keyframes slideIn {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .chart-container {
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    border-radius: 16px;
    padding: 1.5rem;
    margin-top: 1.5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .chart-container:hover {
    transform: translateY(-5px);
  }
  
  .metric-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .metric-content h3 {
    font-size: 0.875rem;
    color: var(--text-secondary);
    margin-bottom: 0.5rem;
  }
  
  .metric-content p {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
  }
  
  .chart-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 16px;
    padding: 16px;
  }
  
  .chart-card {
    background: white;
    border-radius: 8px;
    padding: 16px;
    min-height: 350px;
  }
  
  .table-toolbar {
    padding: 12px 16px;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .table-search {
    position: relative;
    width: 300px;
  }
  
  .table-search input {
    width: 100%;
    padding: 8px 12px 8px 32px;
    border: 1px solid var(--border-color);
    border-radius: 6px;
  }
  
  .table-search svg {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--text-secondary);
  }
  
  .table-scroll {
    overflow-x: auto;
    padding: 0 16px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    padding: 12px 16px;
    text-align: left;
    border-bottom: 1px solid var(--border-color);
  }
  
  th {
    font-weight: 600;
    background: #f8fafc;
  }
  
  .status-container {
    display: flex;
    gap: 8px;
  }
  
  .status-pill {
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 500;
  }
  
  @media (max-width: 768px) {
    .dashboard-content {
      padding: 12px;
    }
    
    .metrics {
      grid-template-columns: 1fr;
      gap: 12px;
    }
    
    .chart-container {
      grid-template-columns: 1fr;
    }
    
    .table-toolbar {
      flex-direction: column;
      gap: 12px;
    }
    
    .table-search {
      width: 100%;
    }
    
    .table-scroll {
      margin: 0 -16px;
      padding: 0;
    }
    
    th, td {
      padding: 8px 12px;
    }
  }
  
  .collapsible .section-content {
    transition: max-height 0.3s ease-out;
  }
  
  .collapsible.collapsed .section-content {
    max-height: 0;
    overflow: hidden;
  }
  
  .toggle-icon {
    margin-left: auto;
    transition: transform 0.3s ease;
  }
  
  .toggle-icon.rotated {
    transform: rotate(180deg);
  }
  
  .filters {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 16px;
    padding: 16px;
  }
  
  .filter {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  
  .filter label {
    font-size: 14px;
    color: var(--text-secondary);
  }
  
  .filter select,
  .filter input {
    padding: 8px;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    font-size: 14px;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .animate-in {
    animation: fadeIn 0.3s ease-out forwards;
  }
  
  /* Fix scrolling issues */
  html, body {
    height: 100%;
    margin: 0;
    overflow-y: auto;
  }
  
  .dashboard-content {
    min-height: 100vh;
    padding: 16px;
    background-color: var(--primary-bg);
    overflow-y: auto;
  }
  
  /* Improve chart type controls */
  .chart-type-controls {
    margin-left: auto;
    display: flex;
    gap: 8px;
  }
  
  .chart-type-btn {
    background: transparent;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    padding: 6px 10px;
    cursor: pointer;
    color: var(--text-secondary);
    transition: all 0.2s ease;
  }
  
  .chart-type-btn:hover {
    background: var(--accent-color);
    color: white;
    border-color: var(--accent-color);
  }
  
  .chart-type-btn.active {
    background: var(--accent-color);
    color: white;
    border-color: var(--accent-color);
  }
  
  /* Enhanced chart cards */
  .chart-card {
    background: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .chart-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Improved metric cards */
  .metric-card {
    background: linear-gradient(145deg, #ffffff, #f5f7fa);
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease;
  }
  
  .metric-card:hover {
    transform: translateY(-2px);
  }
  
  .metric-icon {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(52, 152, 219, 0.1);
  }
  
  .metric-trend {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 0.875rem;
    font-weight: 500;
  }
  
  .metric-trend.positive {
    background-color: rgba(46, 204, 113, 0.1);
    color: var(--success-color);
  }
  
  .metric-trend.negative {
    background-color: rgba(231, 76, 60, 0.1);
    color: var(--danger-color);
  }
  
  .export-btn {
    background-color: #4f46e5;
    color: white;
  }
  
  .export-btn:hover {
    background-color: #4338ca;
  }
  
  /* Improved scrollbar styling */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 4px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
  }
  
  /* Additional responsive improvements */
  @media (max-width: 768px) {
    .dashboard-content {
      padding: 12px;
    }
    
    .chart-type-controls {
      position: absolute;
      top: 12px;
      right: 12px;
    }
    
    .chart-type-btn {
      padding: 4px 8px;
    }
    
    .metric-card {
      padding: 16px;
    }
  }
  
  /* Enhanced animations */
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .animate-in {
    animation: slideIn 0.3s ease-out forwards;
  }
  
  /* Loading state styles */
  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .loading-spinner {
    width: 40px;
    height: 40px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid var(--accent-color);
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Reset and base styles */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html, body {
    height: 100%;
    width: 100%;
    overflow: hidden; /* Prevent double scrollbars */
  }
  
  /* Main container */
  .dashboard-content {
    height: 100vh;
    width: 100%;
    overflow-y: auto;
    background-color: var(--primary-bg);
    padding: 16px;
    position: relative;
  }
  
  /* Scroll containers */
  .scrollable-wrapper {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  .summary-scroll-container {
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 24px;
  }
  
  /* Table scroll */
  .table-scroll {
    overflow-x: auto;
    padding: 0 16px;
  }
  
  @media (max-width: 768px) {
    .table-scroll {
      margin: 0 -16px;
      padding: 0;
    }
  }
  
  /* Custom scrollbar styles */
  .dashboard-content::-webkit-scrollbar,
  .scrollable-wrapper::-webkit-scrollbar,
  .table-scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px; /* For horizontal scrollbar */
  }
  
  .dashboard-content::-webkit-scrollbar-track,
  .scrollable-wrapper::-webkit-scrollbar-track,
  .table-scroll::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  .dashboard-content::-webkit-scrollbar-thumb,
  .scrollable-wrapper::-webkit-scrollbar-thumb,
  .table-scroll::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 4px;
  }
  
  .dashboard-content::-webkit-scrollbar-thumb:hover,
  .scrollable-wrapper::-webkit-scrollbar-thumb:hover,
  .table-scroll::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
  }
  
  /* Enhanced chart area styles */
  .chart-area {
    background: var(--secondary-bg);
    border-radius: 8px;
    padding: 20px;
    margin-top: 16px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .chart-responsive {
    width: 100%;
    min-height: 400px;
    position: relative;
    background: linear-gradient(145deg, #ffffff, #f5f7fa);
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
  }
  
  .chart-responsive:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  }
  
  /* Enhanced chart header */
  .chart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--border-color);
  }
  
  .chart-title {
    font-size: 16px;
    font-weight: 600;
    color: var(--text-primary);
  }
  
  .chart-controls {
    display: flex;
    gap: 8px;
  }
  
  /* Enhanced chart legends */
  .chart-legend {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid var(--border-color);
  }
  
  .legend-item {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    color: var(--text-secondary);
  }
  
  .legend-color {
    width: 12px;
    height: 12px;
    border-radius: 3px;
  }
  
  /* Chart tooltip enhancements */
  .apexcharts-tooltip {
    background: rgba(255, 255, 255, 0.98) !important;
    border: none !important;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
    border-radius: 8px !important;
    padding: 12px !important;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .chart-responsive {
      min-height: 300px;
      padding: 16px;
    }
    
    .chart-header {
      flex-direction: column;
      gap: 12px;
      align-items: flex-start;
    }
    
    .chart-controls {
      width: 100%;
      justify-content: flex-end;
    }
  }
  
  /* Chart loading state */
  .chart-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
  }
  
  .chart-loading-spinner {
    width: 40px;
    height: 40px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid var(--accent-color);
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  /* Chart animations */
  @keyframes chartFadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .chart-animate {
    animation: chartFadeIn 0.5s ease-out forwards;
  }
  
  /* Add these to your existing CSS */
  .chart-card {
    position: relative;
    overflow: hidden;
  }
  
  .chart-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(90deg, var(--accent-color), #2ecc71);
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .chart-card:hover::before {
    opacity: 1;
  }
  
  /* Enhanced metric cards */
  .metric-card {
    position: relative;
    overflow: hidden;
  }
  
  .metric-card::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(90deg, var(--accent-color), #2ecc71);
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease;
  }
  
  .metric-card:hover::after {
    transform: scaleX(1);
  }
  
  /* Update colors for each metric type */
  .metric-card[data-type="orders"] .metric-icon {
    background-color: rgba(52, 152, 219, 0.1);
    color: var(--accent-color);
  }
  
  .metric-card[data-type="weightage"] .metric-icon {
    background-color: rgba(46, 204, 113, 0.1);
    color: var(--success-color);
  }
  
  .metric-card[data-type="approved"] .metric-icon {
    background-color: rgba(243, 156, 18, 0.1);
    color: var(--warning-color);
  }
  
  .metric-card[data-type="pending"] .metric-icon {
    background-color: rgba(231, 76, 60, 0.1);
    color: var(--danger-color);
  }
  
  /* Remove these CSS classes as they're no longer needed */
  .quick-actions-bar,
  .left-actions,
  .right-actions,
  .action-btn,
  .refresh-btn,
  .theme-toggle,
  .fullscreen-btn,
  .export-btn {
    display: none;
  }
  
  .export-button {
    padding: 8px 16px;
    background-color: var(--accent-color);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: auto;
  }
  
  .export-button:hover {
    background-color: var(--accent-color-dark);
  }
  
  /* Hide edit button in summary view */
  .summary-table .edit-button,
  .summary-table .kg-unit {
    display: none;
  }
  
  /* Adjust cell content for summary view */
  .summary-table td[data-unit="kg"] {
    position: relative;
  }
  
  .summary-table td[data-unit="kg"]::after {
    content: none; /* Remove KG suffix */
  }
  
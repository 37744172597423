/* Modern Dashboard Theme with Enhanced Variables */
:root {
  --primary: #4f46e5;
  --primary-light: #818cf8;
  --primary-dark: #3730a3;
  --success: #22c55e;
  --warning: #f59e0b;
  --danger: #ef4444;
  --background: #f8fafc;  
  --card-bg: #ffffff;
  --text-primary: #1e293b;
  --text-secondary: #64748b;
  --border-color: #e2e8f0;
  --hover-bg: #f1f5f9;
  --shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  --transition: all 0.3s ease;
}

/* Enhanced Dashboard Layout */
.admin-dashboard {
  padding: 1.5rem;
  background: var(--background);
  min-height: calc(100vh - 64px);
}

.dashboard-container {
  max-width: 1440px;
  margin: 0 auto;
}

/* Modernized Header Section */
.dashboard-header {
  background: linear-gradient(135deg, var(--primary) 0%, var(--primary-dark) 100%);
  border-radius: 24px;
  padding: 2.5rem;
  margin-bottom: 2rem;
  box-shadow: var(--shadow-lg);
  position: relative;
  overflow: hidden;
  color: white;
}

.header-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.header-main h1 {
  font-size: 2.25rem;
  font-weight: 700;
  margin: 0;
  background: linear-gradient(135deg, #ffffff, #e2e8f0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header-actions {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.primary-action {
  background: white !important;
  color: var(--primary) !important;
  border: none !important;
  height: 42px !important;
  padding: 0 1.5rem !important;
  font-weight: 500 !important;
  border-radius: 12px !important;
  box-shadow: var(--shadow-md) !important;
  display: flex !important;
  align-items: center !important;
  gap: 0.5rem !important;
}

.primary-action:hover {
  transform: translateY(-2px) !important;
  box-shadow: var(--shadow-lg) !important;
}

.action-btn {
  background: rgba(255, 255, 255, 0.1) !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  color: white !important;
  height: 42px !important;
  width: 42px !important;
  border-radius: 12px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  transition: var(--transition) !important;
}

.action-btn:hover {
  background: rgba(255, 255, 255, 0.2) !important;
  transform: translateY(-2px) !important;
}

/* Enhanced Search & Filters Section */
.header-controls {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  align-items: center;
}

.search-container {
  position: relative;
  flex: 1;
  max-width: 400px;
  min-width: 280px;
}

.search-input {
  width: 100%;
  height: 48px;
  padding: 0 1.5rem 0 3.5rem;
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.1);
  color: white;
  font-size: 1rem;
  backdrop-filter: blur(10px);
  transition: var(--transition);
}

.search-input:focus {
  background: rgba(255, 255, 255, 0.15);
  border-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.1);
}

.search-icon {
  position: absolute;
  left: 1.25rem;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.25rem;
}

/* Enhanced Quick Filters */
.quick-filters {
  display: flex;
  gap: 0.75rem;
  flex-wrap: wrap;
}

.filter-chip {
  padding: 0.75rem 1.25rem;
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  cursor: pointer;
  transition: var(--transition);
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-weight: 500;
  font-size: 0.95rem;
}

.filter-chip:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
}

.filter-chip.active {
  background: white;
  color: var(--primary);
  box-shadow: var(--shadow-md);
}

/* Enhanced Metrics Grid */
.metrics-header {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.metric-card {
  background: var(--card-bg);
  border-radius: 12px;
  padding: 1.25rem;
  position: relative;
  overflow: hidden;
  box-shadow: var(--shadow-sm);
  border: 1px solid var(--border-color);
  transition: var(--transition);
}

.metric-card:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
}

.metric-label {
  color: var(--text-secondary);
  font-size: 0.95rem;
  font-weight: 500;
  margin-bottom: 0.75rem;
}

.metric-value {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--text-primary);
  margin-bottom: 0.75rem;
  background: linear-gradient(135deg, var(--primary), var(--primary-light));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.metric-trend {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  color: var(--success);
}

/* Table Container Enhancement */
.content-container {
  background: var(--card-bg);
  border-radius: 12px;
  box-shadow: var(--shadow-md);
  overflow: hidden;
  border: 1px solid var(--border-color);
}

.table-wrapper {
  padding: 0.5rem;
}

/* Enhanced Table Design */
.user-table {
  .ant-table-thead > tr > th {
    background: var(--background) !important;
    font-weight: 600;
    padding: 1rem;
    font-size: 0.875rem;
    color: var(--text-primary);
    border-bottom: 2px solid var(--border-color);
  }

  .ant-table-tbody > tr > td {
    padding: 0.875rem 1rem;
    font-size: 0.875rem;
    color: var(--text-secondary);
    border-bottom: 1px solid var(--border-color);
  }

  .ant-table-tbody > tr:hover > td {
    background: var(--hover-bg);
  }
}

/* Enhanced Status Pills */
.status-pill {
  padding: 0.375rem 0.75rem;
  border-radius: 20px;
  font-size: 0.75rem;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  gap: 0.375rem;
}

.status-active {
  background: rgba(34, 197, 94, 0.1);
  color: var(--success);
}

.status-inactive {
  background: rgba(239, 68, 68, 0.1);
  color: var(--danger);
}

/* Enhanced Platform Tags */
.platform-tag {
  padding: 0.375rem 0.75rem;
  border-radius: 16px;
  font-size: 0.75rem;
  font-weight: 500;
  background: rgba(79, 70, 229, 0.1);
  color: var(--primary);
  transition: var(--transition);
}

.platform-tag:hover {
  transform: translateY(-2px);
  background: var(--primary);
  color: white;
}

/* Enhanced Modal Design */
.user-modal {
  .ant-modal-content {
    border-radius: 12px;
    overflow: hidden;
  }

  .ant-modal-header {
    padding: 1.25rem;
    border-bottom: 1px solid var(--border-color);
  }

  .ant-modal-body {
    padding: 1.25rem;
  }

  .ant-tabs-nav {
    margin-bottom: 2rem;
  }

  .ant-form-item-label > label {
    font-weight: 500;
    color: var(--text-primary);
  }

  .ant-input, .ant-select-selector {
    border-radius: 12px !important;
    border: 1px solid var(--border-color) !important;
    padding: 0.75rem 1rem !important;
    height: auto !important;
    transition: var(--transition);
  }

  .ant-input:hover, .ant-select-selector:hover {
    border-color: var(--primary-light) !important;
  }

  .ant-input:focus, .ant-select-selector:focus {
    border-color: var(--primary) !important;
    box-shadow: 0 0 0 2px rgba(79, 70, 229, 0.1) !important;
  }

  .ant-btn {
    height: 42px;
    border-radius: 12px;
    font-weight: 500;
  }
}

/* Permission Cards */
.permission-card {
  border-radius: 12px;
  box-shadow: var(--shadow-sm);
  margin-bottom: 1rem;
  
  .ant-card-head {
    padding: 0.875rem 1rem;
    border-bottom: 1px solid var(--border-color);
  }
  
  .ant-card-body {
    padding: 1rem;
  }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .admin-dashboard {
    padding: 1rem;
  }

  .dashboard-header {
    padding: 1.5rem;
  }

  .header-main {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .header-main h1 {
    font-size: 1.75rem;
  }

  .header-controls {
    flex-direction: column;
    gap: 1rem;
  }

  .search-container {
    max-width: 100%;
  }

  .metrics-header {
    grid-template-columns: 1fr;
    gap: 0.875rem;
  }
}
:root {
  --primary: #4f46e5;
  --primary-dark: #4338ca;
  --secondary: #64748b;
  --success: #22c55e;
  --warning: #eab308;
  --danger: #ef4444;
  --background: #f8fafc;
  --sidebar: #1e293b;
  --text: #334155;
  --text-light: #94a3b8;
  --border: #e2e8f0;
  --shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
}

.dashboard-layout {
  display: flex;
  min-height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.main-container {
  flex: 1;
  margin-left: 250px;
  width: calc(100% - 250px);
  min-height: 100vh;
  transition: margin-left 0.3s ease;
  overflow: hidden;
}

.content {
  flex: 1;
  padding: 1rem;
  overflow: hidden;
}

.top-header {
  height: 60px;
  padding: 0 1.25rem;
  position: sticky;
  top: 0;
  z-index: 40;
}

/* Sidebar Styles - Updated */
.sidebar {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 250px;
  position: fixed;
  left: 0;
  top: 0;
  background: linear-gradient(180deg, #1e293b 0%, #0f172a 100%);
  z-index: 1000;
  overflow: hidden;
}

.sidebar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

/* Optimized Sidebar Header Styles */
.sidebar-header {
  padding: 1.5rem 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.brand-container {
  width: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
}

.brand-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
}

/* Main logo styling */
.brand-logo {
  text-align: center;
  margin-bottom: 0.75rem;
  display: flex;
  justify-content: center;
}

.company-logo {
  height: 45px;
  width: auto;
  filter: brightness(0) invert(1);
  transition: transform 0.3s ease;
}

/* Brand logo styling - Updated */
.brand {
  text-align: center;
  width: 100%;
  margin-bottom: 0.75rem;
  display: flex;
  justify-content: center;
}

.brandlogo-logo {
  height: 52px;
  width: auto;
  filter: brightness(0) invert(1);
  transition: transform 0.3s ease;
}

/* Brand info section */
.brand-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.powered-text {
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 0.25rem;
}

/* Orange section */
.orange-section {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-top: 0.25rem;
}

.orange-icon {
  height: 28px;
  width: auto;
  filter: brightness(0) invert(1);
}

.orange-text {
  font-size: 1.35rem;
  color: white;
  font-weight: 500;
  text-transform: lowercase;
  letter-spacing: 0.5px;
}

/* Powered by section styling */
.brand-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.powered-by-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.powered-text {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
}

.product-text {
  font-size: 0.75rem;
  color: white;
  font-weight: 500;
}

.orange-icon {
  height: 16px;
  width: auto;
  filter: brightness(0) invert(1);
}

/* Hover effects */
.brand-section:hover .orange-icon {
  transform: rotate(15deg);
}

.powered-by:hover {
  opacity: 1;
}

.powered-by:hover .company-logo {
  transform: scale(1.05);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .sidebar-header {
    padding: 0.5rem 0.375rem;
  }

  .product-name {
    font-size: 1.5rem;
  }

  .company-logo {
    height: 14px;
  }
}

.sidebar-nav {
  flex: 1;
  overflow-y: auto;
  padding: 0.75rem 0.5rem;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.sidebar-nav::-webkit-scrollbar {
  display: none;
}

.nav-group {
  margin-bottom: 0.25rem;
}

.nav-item {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  margin: 0.125rem 0;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.813rem;
}

.nav-item:hover {
  background: rgba(255, 255, 255, 0.08);
  color: white;
  transform: translateX(3px);
}

.nav-item.active {
  background: linear-gradient(90deg, #f59e0b 0%, #d97706 100%);
  color: white;
  font-weight: 500;
  box-shadow: 0 2px 8px rgba(245, 158, 11, 0.2);
}

.nav-icon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  flex-shrink: 0;
}

.nav-item:hover .nav-icon {
  transform: scale(1.1);
}

.submenu {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  margin-left: 1rem;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.submenu.expanded {
  max-height: 1000px;
  opacity: 1;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.submenu-item {
  display: flex;
  flex-direction: column;
  padding: 0.75rem 1rem;
  margin: 0.25rem 0.5rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.813rem;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.submenu-item:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateX(5px);
  border-color: rgba(255, 255, 255, 0.1);
}

.submenu-item.active {
  background: rgba(245, 158, 11, 0.15);
  border-color: rgba(245, 158, 11, 0.3);
  color: #f59e0b;
}

.submenu-content {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
}

.submenu-header {
  display: flex;
  align-items: center;
}

.submenu-title {
  font-size: 0.875rem;
  font-weight: 500;
  color: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.submenu-description {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.5);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.submenu-item.active .submenu-description {
  color: rgba(245, 158, 11, 0.7);
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.submenu-item {
  padding: 0.5rem 0.5rem;
  border-radius: 6px;
  font-size: 0.813rem;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  color: var(--text-light);
}

.submenu-item svg {
  width: 20px;
  height: 20px;
  stroke-width: 2;
}

.submenu-item:hover {
  background: rgba(255, 255, 255, 0.05);
  color: white;
  transform: translateX(3px);
}

.submenu-item.active {
  background: rgba(245, 158, 11, 0.15);
  color: #f59e0b;
}

.submenu-icon {
  width: 14px;
  height: 14px;
  margin-right: 8px;
  flex-shrink: 0;
}

.sidebar-footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  padding: 1rem;
  background: inherit;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: auto;
}

.logout-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  padding: 0.75rem 1rem;
  border: none;
  background: rgba(239, 68, 68, 0.1);
  color: #ef4444;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.875rem;
}

.logout-btn:hover {
  background: rgba(239, 68, 68, 0.2);
  transform: translateY(-1px);
}

.logout-btn:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.logout-icon {
  width: 16px;
  height: 16px;
}

.logout-btn:hover .logout-icon {
  transform: translateX(2px);
}

/* Arrow animation */
.arrow {
  margin-left: auto;
  width: 16px;
  height: 16px;
  transition: transform 0.2s ease;
  opacity: 0.5;
}

.arrow.expanded {
  transform: rotate(-180deg);
  opacity: 1;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .sidebar {
    position: fixed;
    z-index: 50;
    transform: translateX(0);
  }

  .sidebar.collapsed {
    transform: translateX(-100%);
  }
}

@media (max-width: 768px) {
  .sidebar {
    transform: translateX(-100%);
    width: 80%;
    max-width: 300px;
  }

  .sidebar.active {
    transform: translateX(0);
  }

  .main-container {
    margin-left: 0;
    width: 100%;
  }

  .sidebar-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  .sidebar-overlay.active {
    display: block;
  }

  .content {
    padding: 0.5rem;
  }

  .powerbi-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    transition: opacity 0.3s ease-in-out;
  }
}
@keyframes loading {
  0% {
    transform: scaleX(0);
  }
  50% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
    transform-origin: right;
  }
}
/* Main Content Styles */
.main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: white;
}

.top-header {
  height: 60px;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  padding: 0.75rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 10;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.menu-toggle {
  background: none;
  border: none;
  color: var(--text);
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.375rem;
  transition: all 0.2s ease;
}

.menu-toggle:hover {
  background: var(--background);
}

.page-title {
  font-size: 1.5rem;
  background: linear-gradient(45deg, #f59e0b, #d97706);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 700;
  margin-left: 1rem;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.notification-center {
  display: flex;
  gap: 1rem;
}

.notification-btn {
  position: relative;
  background: none;
  border: none;
  color: var(--text);
  padding: 0.5rem;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.notification-btn:hover {
  background: var(--background);
}

.notification-badge {
  position: absolute;
  top: -2px;
  right: -2px;
  background: var(--danger);
  color: white;
  font-size: 0.75rem;
  padding: 0.125rem 0.375rem;
  border-radius: 999px;
  min-width: 18px;
  text-align: center;
}

.user-profile {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.5rem;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.user-profile:hover {
  background: var(--background);
}

.avatar {
  width: 36px;
  height: 36px;
  border-radius: 999px;
  object-fit: cover;
}

.user-name {
  font-weight: 500;
  color: var(--text);
}

.content {
  height: calc(100vh - 60px);
  overflow: auto;
  padding: 1.5rem;
  background: #f8fafc;
}

.content-container {
  height: 100%;
  background: white;
  border-radius: 0;
  box-shadow: none;
  overflow: hidden;
}

/* PowerBI and other embedded content */
.powerbi-container {
  position: relative;
  width: 100%;
  height: 100%;
  background: white;
}
.loading-state {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  z-index: 10;
}

/* For regular content pages */
.page-content {
  height: 100%;
  padding: 1.5rem;
  overflow: auto;
  background: white;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .sidebar {
    position: fixed;
    height: 100vh;
    z-index: 50;
  }
  
  .dashboard-layout {
    grid-template-columns: 1fr;
  }
  
  .content {
    padding: 0;
  }
  
  .content-container {
    border-radius: 0.75rem;
  }
}

@media (max-width: 768px) {
  .content {
    padding: 0.5rem;
  }
  
  .content-container {
    border-radius: 0.5rem;
  }
  
  .page-content {
    padding: 1rem;
  }
}

/* Loading states */
.content-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
}

/* Animation for content transitions */
.content-container {
  animation: none;
  transition: none;
}

/* Scrollbar styling */
.content::-webkit-scrollbar,
.page-content::-webkit-scrollbar {
  display: none;
}

/* Animation Classes */
.arrow {
  transition: transform 0.2s ease;
}

.arrow.expanded {
  transform: rotate(180deg);
}

/* Additional utility classes as needed */
.full-bleed {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.status-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
}

.nav-item,
.submenu-item,
.content-container {
  transition: all 0.2s ease-in-out;
}

/* Gradient text effect */
.gradient-text {
  background: linear-gradient(45deg, #4f46e5, #06b6d4);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 700;
}

/* Glass effect for buttons */
.glass-effect {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
}

/* Update existing styles */
.sidebar {
  background: linear-gradient(180deg, #1e293b 0%, #0f172a 100%);
  box-shadow: 4px 0 15px rgba(0, 0, 0, 0.1);
}

.nav-item {
  margin: 0.5rem 1rem;
  border-radius: 10px;
  transition: all 0.3s ease;
}

.nav-item.active {
  background: linear-gradient(90deg, #4f46e5 0%, #06b6d4 100%);
  transform: translateX(5px);
}

.nav-item:hover {
  transform: translateX(8px);
  background: rgba(255, 255, 255, 0.1);
}

.top-header {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.notification-btn {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0.75rem;
  border-radius: 12px;
  transition: all 0.3s ease;
}

.notification-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

/* Settings modal overlay */
.settings-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.settings-modal {
  background: white;
  border-radius: 20px;
  padding: 2rem;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  animation: modalSlideIn 0.3s ease;
}

@keyframes modalSlideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Card effects */
.content-container {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin: 1rem;
}

/* Hover effects for interactive elements */
.submenu-item:hover {
  background: linear-gradient(90deg, rgba(79, 70, 229, 0.1) 0%, rgba(6, 182, 212, 0.1) 100%);
  transform: translateX(8px);
}

/* Animation for notification badges */
.notification-badge {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* User profile section enhancement */
.user-profile {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0.75rem 1rem;
  border-radius: 15px;
  transition: all 0.3s ease;
}

.user-profile:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.avatar {
  border: 2px solid #4f46e5;
  padding: 2px;
}

/* Add these new styles */

/* User Profile Section */
.user-profile-section {
  margin-right: 1.5rem;
}

.user-profile-card {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 1rem;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease;
}

.user-profile-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #4f46e5;
  padding: 2px;
  object-fit: cover;
}

.user-info {
  display: flex;
  flex-direction: column;
}

.user-name {
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--text);
  margin: 0;
}

.user-role {
  font-size: 0.75rem;
  color: var(--text-light);
}

.user-status-badge {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.75rem;
  color: #22c55e;
  background: rgba(34, 197, 94, 0.1);
  padding: 0.25rem 0.75rem;
  border-radius: 999px;
}

.status-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.status-dot.online {
  background: #22c55e;
  box-shadow: 0 0 0 2px rgba(34, 197, 94, 0.2);
}

/* Dashboard Container and Stats */
.dashboard-container {
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.stat-card {
  background: white;
  border-radius: 16px;
  padding: 1.5rem;
  display: flex;
  align-items: flex-start;
  gap: 1.25rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.stat-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  opacity: 0;
  transition: opacity 0.3s ease;
}

.stat-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
}

.stat-card:hover::before {
  opacity: 1;
}

.stat-icon {
  width: 48px;
  height: 48px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.stat-icon.orders {
  background: linear-gradient(135deg, #4f46e5, #06b6d4);
  color: white;
}

.stat-icon.revenue {
  background: linear-gradient(135deg, #22c55e, #16a34a);
  color: white;
}

.stat-icon.users {
  background: linear-gradient(135deg, #f59e0b, #d97706);
  color: white;
}

.stat-icon.conversion {
  background: linear-gradient(135deg, #6366f1, #4f46e5);
  color: white;
}

.stat-info {
  flex: 1;
}

.stat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.stat-header h3 {
  font-size: 0.875rem;
  color: var(--text-light);
  margin: 0;
  font-weight: 500;
}

.stat-timeframe {
  font-size: 0.75rem;
  color: var(--text-light);
  background: rgba(0, 0, 0, 0.05);
  padding: 0.25rem 0.5rem;
  border-radius: 999px;
}

.stat-value {
  font-size: 1.75rem;
  font-weight: 600;
  color: var(--text);
  margin: 0.5rem 0;
  letter-spacing: -0.5px;
}

.stat-trend {
  display: inline-flex;
  align-items: center;
  gap: 0.375rem;
  font-size: 0.75rem;
  padding: 0.375rem 0.75rem;
  border-radius: 999px;
  margin-top: 0.5rem;
}

.stat-trend.positive {
  background: rgba(34, 197, 94, 0.1);
  color: #22c55e;
}

.stat-trend.negative {
  background: rgba(239, 68, 68, 0.1);
  color: #ef4444;
}

.stat-trend svg {
  width: 16px;
  height: 16px;
}

/* Add animation for stat values */
@keyframes countUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.stat-value {
  animation: countUp 0.5s ease-out forwards;
}

/* Responsive adjustments */
@media (max-width: 1280px) {
  .stats-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .stats-grid {
    grid-template-columns: 1fr;
  }
  
  .stat-card {
    padding: 1.25rem;
  }
  
  .stat-value {
    font-size: 1.5rem;
  }
}

/* Update PowerBI wrapper styles */
.powerbi-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 12px;
}

.dashboard-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow: hidden;
}

/* Style for the left navigation pane */
.powerbi-wrapper iframe {
  border: none;
  width: 100%;
  height: 100%;
}

/* Adjust container padding to accommodate left navigation */
.dashboard-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow: hidden;
}

/* Empty State Styling */
.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 2rem;
  text-align: center;
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.empty-state-text {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text);
  margin: 1rem 0 0.5rem;
}

.empty-state-subtext {
  font-size: 0.875rem;
  color: var(--text-light);
  max-width: 400px;
  line-height: 1.6;
}

/* Loading Spinner */
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid rgba(245, 158, 11, 0.1);
  border-radius: 50%;
  border-top-color: #f59e0b;
  animation: spin 1s ease-in-out infinite;
  margin-bottom: 1.5rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Update Empty State */
.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 2rem;
  text-align: center;
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.empty-state-text {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text);
  margin: 1rem 0 0.5rem;
}

.empty-state-subtext {
  font-size: 0.875rem;
  color: var(--text-light);
  max-width: 400px;
  line-height: 1.6;
}

/* Add this in your HTML head */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');

.powerbi-report {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100% !important;
  height: 100% !important;
}

.powerbi-wrapper {
  position: relative;
  height: 100% !important;
  width: 100% !important;
  margin: 0 !important;
  border-radius: 0 !important;
}

.dashboard-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow: hidden;
}

.content {
  height: 100%;
  overflow: auto;
  padding: 0;
}

.arrow {
  transition: transform 0.3s ease;
}

.arrow.expanded {
  transform: rotate(180deg);
}

.submenu {
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.nav-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  cursor: pointer;
}

.submenu-item {
  padding: 8px 10px 8px 40px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.submenu-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.submenu-content {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}

.submenu-title {
  font-weight: 500;
}

.submenu-description {
  font-size: 0.8rem;
  color: #666;
  margin-top: 2px;
}

/* New Orange section styling */
.orange-section {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.orange-icon {
  height: 20px;
  width: auto;
  filter: brightness(0) invert(1);
}

.orange-text {
  font-size: 1rem;
  color: white;
  font-weight: 500;
  text-transform: lowercase;
}

/* Add smooth scrolling and better scrollbar styling */
.content {
  scroll-behavior: smooth;
  scrollbar-width: thin;
  scrollbar-color: #cbd5e1 #f1f5f9;
}

.content::-webkit-scrollbar {
  width: 8px;
}

.content::-webkit-scrollbar-track {
  background: #f1f5f9;
}

.content::-webkit-scrollbar-thumb {
  background-color: #cbd5e1;
  border-radius: 4px;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .dashboard-layout {
    position: relative;
  }

  .sidebar {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1000;
    transform: translateX(-100%);
    background: var(--sidebar-bg);
  }

  .sidebar.collapsed {
    transform: translateX(0);
  }

  .main-container {
    max-width: 100%;
    margin-left: 0;
  }

  .top-header {
    padding: 0.5rem;
  }

  .mobile-menu-toggle {
    display: block;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 1001;
    padding: 0.75rem;
    border-radius: 50%;
    background: var(--primary-color);
    color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  }

  .powerbi-wrapper {
    height: calc(100vh - 120px);
  }
}

/* Ensure content is centered */
.dashboard-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.glass-effect {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
}

/* PowerBI Container Styles */
.powerbi-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.powerbi-embed {
  width: 100% !important;
  height: 100% !important;
  border: none;
}

.loading-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.9);
}

.loading-content {
  text-align: center;
  padding: 2rem;
  border-radius: 1rem;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.1);
}
.loading-progress {
  margin-top: 1.5rem;
  width: 200px;
}

.loading-bar {
  height: 4px;
  background: #e2e8f0;
  border-radius: 2px;
  overflow: hidden;
}

.loading-bar-fill {
  height: 100%;
  background: linear-gradient(90deg, #3b82f6, #8b5cf6);
  animation: loading 2s ease-in-out infinite;
  transform-origin: left;
}

.loader-icon {
  width: 2.5rem;
  height: 2.5rem;
  animation: spin 1s linear infinite;
  margin: 0 auto;
  color: var(--primary);
}

.loading-text {
  margin-top: 1rem;
  color: var(--text);
}

/* Mobile Navigation Toggle */
.mobile-nav-toggle {
  display: none;
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 1001;
  padding: 0.5rem;
  background: var(--primary);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.mobile-nav-toggle:hover {
  background: var(--primary-dark);
}

.mobile-nav-toggle:focus {
  outline: none;
}

/* Updated Sidebar Styles */
.sidebar {
  width: 250px;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  transition: transform 0.3s ease;
  z-index: 1000;
  background: linear-gradient(180deg, #1e293b 0%, #0f172a 100%);
}

/* Mobile Styles */
@media (max-width: 768px) {
  .mobile-nav-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sidebar {
    transform: translateX(-100%);
    width: 80%;
    max-width: 300px;
  }

  .sidebar.active {
    transform: translateX(0);
  }

  .main-container {
    margin-left: 0;
    width: 100%;
  }

  .powerbi-container {
    height: calc(100vh - 60px);
  }

  .powerbi-embed {
    height: 100% !important;
  }

  /* Overlay when sidebar is open */
  .sidebar-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  .sidebar-overlay.active {
    display: block;
  }
}

/* Animation for menu icon */
.menu-icon {
  transition: transform 0.3s ease;
}

.menu-icon.active {
  transform: rotate(180deg);
}

@media (max-width: 768px) {
  .powerbi-container {
    height: calc(100vh - 60px);
    overflow: hidden;
  }

  .powerbi-embed {
    transform-origin: top left;
    transform: scale(1);
  }
}

@media (max-width: 768px) {
  .powerbi-wrapper {
    height: calc(100vh - 8rem);
    border-radius: 8px;
  }

  .powerbi-embed {
    width: 100% !important;
    height: 100% !important;
  }
}

.dashboard-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dashboard-content {
  flex: 1;
  overflow: hidden;
  padding: 24px;
  background-color: var(--primary-bg);
}

.content-wrapper {
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
}

/* Clean scrollbar styles */
.dashboard-content::-webkit-scrollbar {
  width: 8px;
}

.dashboard-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.dashboard-content::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
}

.dashboard-content::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

/* Only allow scrolling in table containers */
.po-table-container,
.master-table-container {
  overflow-y: auto;
  height: calc(100vh - 180px); /* Adjust based on your header/footer heights */
}

/* Hide scrollbar but keep functionality for webkit browsers */
.po-table-container::-webkit-scrollbar,
.master-table-container::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.po-table-container::-webkit-scrollbar-track,
.master-table-container::-webkit-scrollbar-track {
  background: transparent;
}

.po-table-container::-webkit-scrollbar-thumb,
.master-table-container::-webkit-scrollbar-thumb {
  background-color: rgba(203, 213, 225, 0.4);
  border-radius: 3px;
}

/* For Firefox */
.po-table-container,
.master-table-container {
  scrollbar-width: thin;
  scrollbar-color: rgba(203, 213, 225, 0.4) transparent;
}

/* Remove scrolling from other containers */
.dashboard-content {
  flex: 1;
  overflow: hidden;
  padding: 24px;
  background-color: var(--primary-bg);
}

.content-wrapper {
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
}
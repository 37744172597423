.diff-viewer {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 8px;
    max-height: 500px;
    overflow: auto;
    font-family: monospace;
    white-space: pre-wrap;
    word-break: break-all;
}

.diff-line {
    padding: 2px 4px;
    margin: 1px 0;
}

.diff-line.removed {
    background-color: #ffe6e6;
    color: #c41d7f;
}

.diff-line.added {
    background-color: #e6ffe6;
    color: #389e0d;
}

.diff-line.unchanged {
    color: #595959;
}
.metrics-container {
  background: var(--secondary-bg, #ffffff);
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
  overflow: hidden;
}

.metrics {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 16px;
  padding: 20px;
}

.metric-card {
  background: white;
  border-radius: 12px;
  padding: 24px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.metric-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
}

.metric-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.metric-icon {
  width: 52px;
  height: 52px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(52, 152, 219, 0.1);
  color: #3498db;
  transition: all 0.3s ease;
}

.metric-card:hover .metric-icon {
  transform: scale(1.1);
}

.metric-trend {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 14px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.metric-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.metric-value {
  font-size: 32px;
  font-weight: 700;
  color: #1e293b;
  margin: 12px 0;
  transition: all 0.3s ease;
}

.metric-card:hover .metric-value {
  transform: scale(1.05);
}

@media (max-width: 1200px) {
  .metrics {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .metrics {
    grid-template-columns: 1fr;
    padding: 16px;
  }
  
  .metric-card {
    padding: 20px;
  }
  
  .metric-value {
    font-size: 28px;
  }
}

/* Enhanced animations */
@keyframes slideUpFade {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-slide-up {
  animation: slideUpFade 0.5s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.metric-card:nth-child(1) { animation-delay: 0.1s; }
.metric-card:nth-child(2) { animation-delay: 0.2s; }
.metric-card:nth-child(3) { animation-delay: 0.3s; }
.metric-card:nth-child(4) { animation-delay: 0.4s; }

.metric-trend.positive {
  background: rgba(46, 204, 113, 0.1);
  color: #2ecc71;
}

.metric-trend.negative {
  background: rgba(231, 76, 60, 0.1);
  color: #e74c3c;
}

.metric-content h3 {
  font-size: 14px;
  color: #64748b;
  margin-bottom: 8px;
}

.metric-period {
  font-size: 12px;
  color: #94a3b8;
}

.charts-container {
  background: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.dashboard-header {
  margin-bottom: 2rem;
  padding: 1rem;
}

.dashboard-header h1 {
  font-size: 2rem;
  color: #1e293b;
  margin-bottom: 0.5rem;
}

.dashboard-subtitle {
  color: #64748b;
  font-size: 1rem;
}

.charts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  gap: 24px;
  margin-bottom: 24px;
}

.chart-card {
  background: white;
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.chart-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.chart-title {
  display: flex;
  align-items: center;
  gap: 8px;
}

.chart-title h3 {
  font-size: 1.2rem;
  color: #1e293b;
  margin: 0;
}

.chart-period-selector {
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background: white;
  color: #64748b;
  font-size: 0.9rem;
  cursor: pointer;
}

/* Animations */
@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.animate-slide-up {
  animation: slideUp 0.5s ease-out forwards;
}

.animate-fade-in {
  animation: fadeIn 0.5s ease-out forwards;
}

.pulse {
  animation: pulse 2s infinite;
}

/* Enhanced metric card styles */
.metric-card {
  position: relative;
  overflow: hidden;
}

.metric-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, #3b82f6, #60a5fa);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.metric-card:hover::before {
  opacity: 1;
}

/* Marketing section styles */
.marketing-section {
  background: white;
  border-radius: 12px;
  padding: 20px;
  margin-top: 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.marketing-metrics {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
  padding: 16px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .charts-grid {
    grid-template-columns: 1fr;
  }
  
  .dashboard-header h1 {
    font-size: 1.5rem;
  }
} 

/* Enhanced Header Styles */
.executive-header {
  background: linear-gradient(135deg, #1a237e 0%, #0d47a1 100%);
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 24px;
  color: white;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.header-content {
  margin-bottom: 20px;
}

.header-content h1 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 8px;
  background: linear-gradient(to right, #fff, #e3f2fd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header-content p {
  font-size: 1.1rem;
  opacity: 0.9;
  margin: 0;
}

.quick-stats {
  display: flex;
  gap: 24px;
  margin-top: 16px;
}

.stat-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  font-size: 0.9rem;
}

/* Campaign Section Styles */
.campaign-metrics {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 24px;
  margin-top: 20px;
}

.campaign-stats {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
}

.stat-card {
  background: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.stat-card h4 {
  font-size: 0.9rem;
  color: #64748b;
  margin: 0 0 8px 0;
}

.stat-card p {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1e293b;
  margin: 0 0 8px 0;
}

.stat-value {
  font-size: 1.2rem;
  font-weight: 600;
  color: #3b82f6;
}

.trend {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 0.8rem;
}

.trend.positive {
  background: rgba(34, 197, 94, 0.1);
  color: #16a34a;
}

.trend.negative {
  background: rgba(239, 68, 68, 0.1);
  color: #dc2626;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .campaign-metrics {
    grid-template-columns: 1fr;
  }
  
  .quick-stats {
    flex-direction: column;
    gap: 12px;
  }
}

@media (max-width: 768px) {
  .executive-header {
    padding: 20px;
  }
  
  .header-content h1 {
    font-size: 2rem;
  }
  
  .charts-grid {
    grid-template-columns: 1fr;
  }
}

/* New Marketplace Section Styles */
.marketplace-grid {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 24px;
  margin-top: 20px;
}

.platform-card {
  background: white;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  border-left: 4px solid transparent;
}

.platform-card:nth-child(1) { border-left-color: #3b82f6; }
.platform-card:nth-child(2) { border-left-color: #4f46e5; }
.platform-card:nth-child(3) { border-left-color: #06b6d4; }
.platform-card:nth-child(4) { border-left-color: #8b5cf6; }

.platform-card:hover {
  transform: translateX(8px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.platform-card h4 {
  color: #1e293b;
  margin: 0 0 8px 0;
}

.platform-value {
  font-size: 1.5rem;
  font-weight: 600;
  color: #4F46E5;
  margin: 8px 0;
}

.platform-stats {
  display: flex;
  gap: 16px;
  font-size: 0.9rem;
  color: #64748b;
}

/* Satisfaction Section Styles */
.satisfaction-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin-top: 20px;
}

.metric-pill {
  background: white;
  border-radius: 20px;
  padding: 12px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.metric-pill:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.metric-pill .value {
  font-weight: 600;
  color: #4F46E5;
}

/* Fulfillment Section Styles */
.fulfillment-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin-top: 20px;
}

.fulfillment-card {
  background: white;
  border-radius: 16px;
  padding: 24px;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.fulfillment-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
}

.fulfillment-icon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: linear-gradient(135deg, #4F46E5, #06B6D4);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 16px;
}

/* Enhanced Animations */
@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.animate-float {
  animation: float 3s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(79, 70, 229, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(79, 70, 229, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(79, 70, 229, 0);
  }
}

.pulse {
  animation: pulse 2s infinite;
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
  .marketplace-grid,
  .satisfaction-grid {
    grid-template-columns: 1fr;
  }
  
  .fulfillment-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .fulfillment-grid {
    grid-template-columns: 1fr;
  }
}

/* Updated Metrics Container */
.metrics-container {
  background: var(--secondary-bg, #ffffff);
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
  overflow: hidden;
}

/* Order Fulfillment Section Styles */
.order-fulfillment-container {
  background: var(--secondary-bg, #ffffff);
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin: 0 0 24px 0; /* Adjusted margin to align with KPI section */
  padding: 24px;
}

.order-fulfillment-container .section-title {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
  padding: 0 0 16px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.order-fulfillment-container .section-title h2 {
  font-size: 1.5rem;
  color: #1e293b;
  margin: 0;
}

.fulfillment-metrics {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
}

.fulfillment-metrics .metric-card {
  background: white;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.fulfillment-metrics .metric-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.fulfillment-metrics .metric-icon {
  background: rgba(59, 130, 246, 0.1);
  color: #3b82f6;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .metrics {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .fulfillment-metrics {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .metrics,
  .fulfillment-metrics {
    grid-template-columns: 1fr;
  }
  
  .order-fulfillment-container {
    padding: 16px;
  }
}

/* Add these new styles for marketplace insights */
.marketplace-insights-container {
  background: var(--secondary-bg, #ffffff);
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.marketplace-metrics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 24px;
  margin-top: 20px;
}

.insight-card {
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.insight-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
}

.insight-card h3 {
  color: #1e293b;
  font-size: 1.2rem;
  margin-bottom: 20px;
  padding-bottom: 12px;
  border-bottom: 2px solid rgba(59, 130, 246, 0.1);
}

.insight-metrics {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.metric {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.progress-bar {
  flex-grow: 1;
  height: 8px;
  background: rgba(59, 130, 246, 0.1);
  border-radius: 4px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background: linear-gradient(90deg, #3b82f6, #60a5fa);
  border-radius: 4px;
  transition: width 1s ease-in-out;
}

.metric .value {
  font-size: 1.5rem;
  font-weight: 600;
  color: #3b82f6;
  margin: 0;
}

/* Enhanced animations */
.insight-card {
  animation: slideUpFade 0.5s ease-out forwards;
}

.progress {
  animation: progressFill 1.5s ease-out forwards;
}

@keyframes progressFill {
  from {
    width: 0;
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .marketplace-metrics-grid {
    grid-template-columns: 1fr;
  }
  
  .insight-card {
    padding: 20px;
  }
}

/* Updated Satisfaction Section Styles */
.satisfaction-section {
  background: var(--secondary-bg, #ffffff);
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.brand-metric {
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border: 1px solid rgba(59, 130, 246, 0.1);
  transition: all 0.3s ease;
}

.brand-metric:hover {
  transform: translateX(8px);
  border-left: 4px solid #4F46E5;
  background: linear-gradient(145deg, #ffffff, #f0f9ff);
}

.brand-metric .value {
  background: linear-gradient(90deg, #3b82f6, #4f46e5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

/* Enhanced Marketplace Chart Styles */
.marketplace-grid {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 24px;
  margin-top: 20px;
}

.chart-card {
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.platform-card {
  background: white;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  border-left: 4px solid transparent;
}

.platform-card:nth-child(1) { border-left-color: #3b82f6; }
.platform-card:nth-child(2) { border-left-color: #4f46e5; }
.platform-card:nth-child(3) { border-left-color: #06b6d4; }
.platform-card:nth-child(4) { border-left-color: #8b5cf6; }

.platform-card:hover {
  transform: translateX(8px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Enhanced Marketing Section Styles */
.marketing-section,
.regional-section {
  background: var(--secondary-bg, #ffffff);
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.campaign-metrics {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 24px;
  margin-top: 20px;
}

.campaign-chart {
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.campaign-stats {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.stat-card {
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.stat-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.stat-card h4 {
  color: #64748b;
  font-size: 0.9rem;
  margin: 0 0 8px 0;
}

.stat-card p {
  color: #1e293b;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0 0 8px 0;
}

.stat-value {
  color: #4F46E5;
  font-weight: 600;
}

/* Regional Section Enhancements */
.regional-section .chart-card {
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .campaign-metrics {
    grid-template-columns: 1fr;
  }
  
  .campaign-stats {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

@media (max-width: 768px) {
  .campaign-stats {
    grid-template-columns: 1fr;
  }
}

/* Enhanced Second Row and Regional Section Styles */
.charts-second-row {
  margin-top: 24px;
}

.regional-performance-section {
  background: var(--secondary-bg, #ffffff);
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.regional-content {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 24px;
  margin-top: 20px;
}

.chart-area {
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.regional-metrics {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
}

.region-card {
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  border-left: 4px solid transparent;
}

.region-card:nth-child(1) { border-left-color: #818CF8; }
.region-card:nth-child(2) { border-left-color: #6366F1; }
.region-card:nth-child(3) { border-left-color: #4F46E5; }
.region-card:nth-child(4) { border-left-color: #4338CA; }
.region-card:nth-child(5) { border-left-color: #3730A3; }

.region-card:hover {
  transform: translateX(8px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.region-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.region-header h4 {
  font-size: 1rem;
  color: #1e293b;
  margin: 0;
}

.region-trend {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 0.875rem;
  padding: 4px 8px;
  border-radius: 20px;
  background: rgba(34, 197, 94, 0.1);
  color: #16a34a;
}

.region-value {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1e293b;
  margin: 8px 0;
}

.region-stats {
  display: flex;
  justify-content: space-between;
  font-size: 0.875rem;
  color: #64748b;
}

.section-title {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
}

.section-title h2 {
  font-size: 1.25rem;
  color: #1e293b;
  margin: 0;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .regional-content {
    grid-template-columns: 1fr;
  }
  
  .regional-metrics {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }
}

@media (max-width: 768px) {
  .regional-metrics {
    grid-template-columns: 1fr;
  }
  
  .region-card {
    margin-bottom: 12px;
  }
}

/* Animation for region cards */
.region-card {
  animation: slideIn 0.5s ease-out forwards;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Enhanced Analytics Container Styles */
.analytics-container {
  background: var(--secondary-bg, #ffffff);
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  margin-bottom: 24px;
}

.charts-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-bottom: 24px;
}

.chart-container {
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  position: relative;
}

.chart-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(90deg, #3b82f6, #4f46e5);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.chart-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
}

.chart-container:hover::before {
  opacity: 1;
}

.chart-container h3 {
  font-size: 1.1rem;
  color: #1e293b;
  margin-bottom: 20px;
  padding-bottom: 12px;
  border-bottom: 2px solid rgba(59, 130, 246, 0.1);
}

.trend-container {
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.trend-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
}

.trend-container h3 {
  font-size: 1.1rem;
  color: #1e293b;
  margin-bottom: 20px;
  padding-bottom: 12px;
  border-bottom: 2px solid rgba(59, 130, 246, 0.1);
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .charts-row {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .analytics-container {
    padding: 16px;
  }
  
  .chart-container,
  .trend-container {
    padding: 16px;
  }
}

/* Chart animations */
@keyframes chartFadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.chart-container,
.trend-container {
  animation: chartFadeIn 0.5s ease-out forwards;
}

/* Enhanced tooltip styles */
.apexcharts-tooltip {
  background: rgba(255, 255, 255, 0.98) !important;
  border: none !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
  border-radius: 8px !important;
  padding: 12px !important;
}

.apexcharts-tooltip-title {
  background: #f8fafc !important;
  border-bottom: 1px solid #e2e8f0 !important;
}

/* Update the chart grid styles */
.chart-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-bottom: 24px;
}

.trend-chart-card {
  background: white;
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  margin-top: 24px;
}

@media (max-width: 768px) {
  .chart-grid {
    grid-template-columns: 1fr;
  }
}

/* Add new animations */
@keyframes fadeScale {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate-fade-scale {
  animation: fadeScale 0.5s ease-out forwards;
}

/* Enhanced chart card styles */
.chart-card {
  position: relative;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.chart-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, #3b82f6, #4f46e5);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.chart-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
}

.chart-card:hover::before {
  opacity: 1;
}

/* Enhanced metric value animation */
.metric-value {
  transition: color 0.3s ease;
}

.metric-card:hover .metric-value {
  color: #4f46e5;
}

/* Pulse animation for icons */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.metric-icon {
  animation: pulse 2s infinite;
}

/* Smooth transition for all interactive elements */
.chart-card,
.metric-card,
.trend-chart-card {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}
.sales-analytics-container {
  padding: 24px;
  max-width: 1400px;
  margin: 0 auto;
  height: calc(100vh - 64px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
}

.sales-analytics-container > * {
  min-height: 0;
  flex-shrink: 0;
}

.sales-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.sales-header h1 {
  font-size: 1.8rem;
  color: #1e293b;
  font-weight: 600;
}

.period-selector select {
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  background-color: white;
  font-size: 0.9rem;
  color: #1e293b;
  cursor: pointer;
}

.time-metrics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 24px;
  margin-bottom: 32px;
}

.sales-metric-card {
  background: white;
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease;
}

.sales-metric-card:hover {
  transform: translateY(-5px);
}

.metric-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.growth-indicator {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 0.9rem;
  font-weight: 500;
}

.growth-indicator.positive {
  background-color: #dcfce7;
  color: #16a34a;
}

.growth-indicator.negative {
  background-color: #fee2e2;
  color: #dc2626;
}

.metric-values {
  display: flex;
  gap: 24px;
  margin-top: 16px;
}

.current-value, .previous-value {
  flex: 1;
}

.current-value span, .previous-value span {
  font-size: 0.85rem;
  color: #64748b;
}

.current-value p, .previous-value p {
  font-size: 1.2rem;
  font-weight: 600;
  color: #1e293b;
  margin: 4px 0;
}

.velocity-metrics {
  margin: 32px 0;
}

.velocity-metrics h2 {
  font-size: 1.4rem;
  color: #1e293b;
  margin-bottom: 20px;
}

.velocity-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.velocity-card {
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border-radius: 12px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.velocity-card svg {
  color: #4F46E5;
  margin-bottom: 12px;
}

.velocity-card h3 {
  font-size: 1rem;
  color: #64748b;
  margin-bottom: 8px;
}

.velocity-card p {
  font-size: 1.4rem;
  font-weight: 600;
  color: #1e293b;
}

.sales-charts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 24px;
  margin-top: 32px;
  width: 100%;
}

.chart-card {
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.chart-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, #4F46E5, #06B6D4);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.chart-card:hover::before {
  opacity: 1;
}

.chart-card h2 {
  font-size: 1.2rem;
  color: #1e293b;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
}

@media (max-width: 768px) {
  .sales-analytics-container {
    height: calc(100vh - 56px);
    padding: 16px;
  }

  .sales-charts-grid {
    grid-template-columns: 1fr;
  }

  .time-metrics-grid {
    grid-template-columns: 1fr;
  }

  .velocity-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Animations */
@keyframes fadeScale {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate-fade-scale {
  animation: fadeScale 0.5s ease-out forwards;
}

.sales-metric-card, .velocity-card, .chart-card {
  animation: slideUp 0.5s ease-out forwards;
}

.platform-performance-card {
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
}

.platform-performance-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.12);
}

.platform-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.platform-header h3 {
  font-size: 1.2rem;
  color: #1e293b;
  font-weight: 600;
}

.growth-badge {
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 500;
}

.platform-metrics {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.metric span {
  font-size: 0.85rem;
  color: #64748b;
}

.metric p {
  font-size: 1.4rem;
  font-weight: 600;
  color: #1e293b;
  margin: 4px 0;
}


/* Platform grid scrolling fix */
.platform-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 24px;
  margin-top: 20px;
  width: 100%;
}

/* Charts grid scrolling fix */
.sales-charts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 24px;
  margin-top: 32px;
  width: 100%;
}

/* Add smooth scrolling */
* {
  scroll-behavior: smooth;
}

/* Ensure content doesn't overflow horizontally */
.sales-header,
.time-metrics-grid,
.platform-overview-section,
.sales-charts-grid {
  max-width: 100%;
  overflow-x: hidden;
}

/* Performance Highlights */
.highlights-section {
  margin: 32px 0;
}

.highlights-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 24px;
  margin-top: 20px;
}

.highlight-card {
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border-radius: 16px;
  padding: 24px;
  display: flex;
  align-items: flex-start;
  gap: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
}

.highlight-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.12);
}

.highlight-icon {
  background: linear-gradient(135deg, #4F46E5, #818CF8);
  color: white;
  padding: 12px;
  border-radius: 12px;
}

.highlight-content h4 {
  color: #64748b;
  font-size: 0.9rem;
  margin-bottom: 8px;
}

.highlight-value {
  font-size: 1.4rem;
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 8px;
}

.highlight-metrics {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 0.9rem;
}

/* SKU Cards - Updated Styles */
.top-skus-section {
  margin: 32px 0;
  width: 100%;
}

.sku-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 24px;
  margin-top: 20px;
  width: 100%;
}

.sku-card {
  background: white;
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.sku-card h4 {
  font-size: 1.1rem;
  color: #1e293b;
  margin: 0;
  font-weight: 600;
}

.sku-sales {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.sku-sales span {
  font-size: 0.9rem;
  color: #64748b;
}

.sku-sales p {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1e293b;
  margin: 0;
}

/* Platform Comparison - Updated Styles */
.platform-comparison-section {
  margin: 32px 0;
  width: 100%;
}

.comparison-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 24px;
  margin-top: 20px;
  width: 100%;
}

.comparison-card {
  background: white;
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
}

.comparison-card h3 {
  font-size: 1.2rem;
  color: #1e293b;
  margin: 0 0 20px 0;
  font-weight: 600;
}

.comparison-metrics {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.comparison-metrics .metric {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 8px;
}

.comparison-metrics .metric svg {
  color: #4F46E5;
  margin-bottom: 4px;
}

.comparison-metrics .metric span {
  font-size: 0.85rem;
  color: #64748b;
  display: block;
}

.comparison-metrics .metric p {
  font-size: 1.1rem;
  font-weight: 600;
  color: #1e293b;
  margin: 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .sku-grid,
  .comparison-grid {
    grid-template-columns: 1fr;
  }

  .comparison-metrics {
    gap: 16px;
  }

  .sku-card,
  .comparison-card {
    padding: 20px;
  }
}

/* Common section header styles */
.top-skus-section h2,
.platform-comparison-section h2 {
  font-size: 1.4rem;
  color: #1e293b;
  margin-bottom: 20px;
  font-weight: 600;
}

/* Enhanced animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.highlight-card,
.sku-card,
.comparison-card {
  animation: fadeInUp 0.5s ease-out forwards;
}

/* Enhanced Chart Styles */
.chart-section {
  margin: 32px 0;
}

.chart-card {
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
}

.chart-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.12);
}

.trend-chart {
  position: relative;
  overflow: hidden;
}

.trend-chart::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(90deg, #4F46E5, #06B6D4, #10B981);
  border-radius: 4px 4px 0 0;
}

.device-chart {
  position: relative;
  background: linear-gradient(145deg, #ffffff, #f8fafc);
}

.device-chart::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(90deg, #4F46E5, #06B6D4, #10B981);
  border-radius: 0 0 4px 4px;
}

/* Chart Grid Layout */
.charts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 24px;
  margin-top: 32px;
}

/* Enhanced Animations */
@keyframes chartFadeIn {
  from {
    opacity: 0;
    transform: translateY(20px) scale(0.95);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.chart-card {
  animation: chartFadeIn 0.6s ease-out forwards;
}

/* Responsive Design for Charts */
@media (max-width: 768px) {
  .charts-grid {
    grid-template-columns: 1fr;
  }
  
  .chart-card {
    padding: 16px;
  }
}

/* Chart Loading State */
.chart-loading {
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border-radius: 16px;
}

.chart-loading::after {
  content: '';
  width: 40px;
  height: 40px;
  border: 4px solid #f3f4f6;
  border-top: 4px solid #4F46E5;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Enhanced Tooltip Styles */
.apexcharts-tooltip {
  background: rgba(255, 255, 255, 0.95) !important;
  backdrop-filter: blur(4px);
  border: none !important;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
  padding: 8px 12px !important;
}

.apexcharts-tooltip-title {
  background: transparent !important;
  border-bottom: 1px solid #e2e8f0 !important;
  padding: 8px 12px !important;
  font-weight: 600 !important;
}

/* Enhanced Card Animations */
@keyframes floatIn {
  0% {
    opacity: 0;
    transform: translateY(20px) scale(0.95);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  }
  50% {
    transform: scale(1.02);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.12);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  }
}

/* Enhanced SKU Card Styles */
.sku-card {
  animation: floatIn 0.6s ease-out forwards;
  position: relative;
  overflow: hidden;
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border: 1px solid rgba(79, 70, 229, 0.1);
}

.sku-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(79, 70, 229, 0.15);
}

.sku-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, #4F46E5, #818CF8);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.sku-card:hover::before {
  opacity: 1;
}

.sku-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.sku-growth {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 20px;
  font-size: 0.85rem;
  font-weight: 500;
  transition: transform 0.3s ease;
}

.sku-growth.positive {
  background: rgba(22, 163, 74, 0.1);
  color: #16a34a;
}

.sku-growth.negative {
  background: rgba(220, 38, 38, 0.1);
  color: #dc2626;
}

.sku-growth:hover {
  transform: scale(1.05);
}

/* Enhanced Platform Comparison Card Styles */
.comparison-card {
  animation: floatIn 0.6s ease-out forwards;
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border: 1px solid rgba(79, 70, 229, 0.1);
}

.comparison-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(79, 70, 229, 0.15);
}

.comparison-metrics .metric {
  position: relative;
  padding: 16px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(8px);
  transition: all 0.3s ease;
}

.comparison-metrics .metric:hover {
  background: rgba(79, 70, 229, 0.05);
  transform: translateY(-2px);
}

.comparison-metrics .metric svg {
  transition: transform 0.3s ease;
}

.comparison-metrics .metric:hover svg {
  transform: scale(1.1);
  color: #4F46E5;
}

/* Loading Animation */
.loading-shimmer {
  background: linear-gradient(
    90deg,
    #f0f0f0 0%,
    #f8f8f8 50%,
    #f0f0f0 100%
  );
  background-size: 1000px 100%;
  animation: shimmer 2s infinite linear;
}

/* Pulse Animation for Important Metrics */
.metric p {
  animation: pulse 2s infinite;
}

/* Hover Effects for Section Headers */
.top-skus-section h2,
.platform-comparison-section h2 {
  position: relative;
  display: inline-block;
  padding-bottom: 8px;
}

.top-skus-section h2::after,
.platform-comparison-section h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 3px;
  background: linear-gradient(90deg, #4F46E5, #818CF8);
  transition: width 0.3s ease;
}

.top-skus-section h2:hover::after,
.platform-comparison-section h2:hover::after {
  width: 100%;
}

/* Enhanced Grid Layout Animation */
.sku-grid,
.comparison-grid {
  perspective: 1000px;
}

.sku-grid > *,
.comparison-grid > * {
  animation: floatIn 0.6s ease-out forwards;
  animation-fill-mode: both;
}

/* Stagger children animations */
.sku-grid > *:nth-child(1),
.comparison-grid > *:nth-child(1) {
  animation-delay: 0.1s;
}

.sku-grid > *:nth-child(2),
.comparison-grid > *:nth-child(2) {
  animation-delay: 0.2s;
}

.sku-grid > *:nth-child(3),
.comparison-grid > *:nth-child(3) {
  animation-delay: 0.3s;
}

/* Interactive Hover States */
.sku-sales p,
.comparison-metrics .metric p {
  transition: all 0.3s ease;
}

.sku-sales:hover p,
.comparison-metrics .metric:hover p {
  color: #4F46E5;
  transform: scale(1.05);
}

/* Glass Morphism Effect */
.comparison-card,
.sku-card {
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  background: rgba(255, 255, 255, 0.7);
}

/* Smooth Transitions */
* {
  transition: all 0.3s ease;
}

/* Focus States */
.sku-card:focus-within,
.comparison-card:focus-within {
  outline: 2px solid #4F46E5;
  outline-offset: 2px;
}

/* Metric Bubbles */
.metrics-bubble-container {
  margin: 32px 0;
}

.bubble-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 24px;
  margin-top: 20px;
}

.metric-bubble {
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border-radius: 20px;
  padding: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.metric-bubble::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: var(--bubble-color);
}

.metric-bubble:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
}

.bubble-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.bubble-label {
  font-size: 0.9rem;
  color: #64748b;
}

.bubble-value {
  font-size: 1.8rem;
  font-weight: 600;
  color: #1e293b;
  margin: 0;
}

.bubble-trend {
  font-size: 0.9rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 4px;
}

.bubble-trend.positive {
  color: #16a34a;
}

.bubble-trend.negative {
  color: #dc2626;
}

/* Insights Section */
.insights-section {
  margin: 32px 0;
}

.insights-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 24px;
  margin-top: 20px;
}

.insight-card {
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border-radius: 16px;
  padding: 24px;
  display: flex;
  gap: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
}

.insight-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
}

.insight-icon {
  background: linear-gradient(135deg, #4F46E5, #818CF8);
  color: white;
  padding: 12px;
  border-radius: 12px;
  height: fit-content;
}

.insight-content {
  flex: 1;
}

.insight-content h3 {
  font-size: 1.1rem;
  color: #1e293b;
  margin: 0 0 8px 0;
}

.insight-content p {
  font-size: 0.9rem;
  color: #64748b;
  margin: 0 0 16px 0;
}

.insight-metric {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
  border-top: 1px solid #e2e8f0;
}

.insight-metric span {
  font-size: 0.85rem;
  color: #64748b;
}

.insight-metric strong {
  font-size: 1rem;
  color: #4F46E5;
}

/* Enhanced Chart Styles */
.funnel-chart,
.revenue-chart {
  position: relative;
  overflow: hidden;
}

.funnel-chart::before,
.revenue-chart::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(90deg, #4F46E5, #06B6D4, #10B981);
  border-radius: 4px 4px 0 0;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .bubble-grid,
  .insights-grid {
    grid-template-columns: 1fr;
  }
  
  .insight-card {
    flex-direction: column;
    gap: 16px;
  }
  
  .insight-icon {
    width: fit-content;
  }
}

/* Enhanced KPI Section */
.kpi-section {
  background: var(--secondary-bg, #ffffff);
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.kpi-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.kpi-card {
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.kpi-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, #3b82f6, #4f46e5);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.kpi-card:hover::before {
  opacity: 1;
}



.quick-stats {
  display: flex;
  gap: 24px;
  margin-top: 16px;
}

.stat-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: rgba(59, 130, 246, 0.1);
  border-radius: 8px;
  color: #1e293b;
}

/* Enhanced Chart Grid */
.charts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  gap: 24px;
  margin-bottom: 24px;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .kpi-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .charts-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .kpi-grid {
    grid-template-columns: 1fr;
  }
  
  .quick-stats {
    flex-direction: column;
  }
}

/* Channel Distribution Styles */
.channel-distribution-section {
  margin: 32px 0;
}

.channel-metrics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 24px;
  margin-top: 20px;
}

.channel-card {
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.channel-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
}

.channel-value {
  font-size: 2rem;
  font-weight: 600;
  color: #1e293b;
  margin: 12px 0;
}

/* Top Products Styles */
.top-products-section {
  margin: 32px 0;
}

.products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 24px;
  margin-top: 20px;
}

.product-card {
  display: flex;
  align-items: center;
  gap: 20px;
  background: white;
  border-radius: 16px;
  padding: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.product-rank {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #4F46E5;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 1.2rem;
}

/* Performance Metrics Styles */
.performance-metrics-section {
  margin: 32px 0;
}

.metrics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 24px;
  margin-top: 20px;
}

.metric-tile {
  background: white;
  border-radius: 16px;
  padding: 24px;
  display: flex;
  gap: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.metric-tile:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
}

.metric-details h3 {
  font-size: 1rem;
  color: #64748b;
  margin-bottom: 8px;
}

.metric-details p {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1e293b;
  margin: 0;
}

.trend {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-size: 0.9rem;
  margin-top: 8px;
}

/* Quick Overview Section */
.quick-overview-section {
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.overview-header {
  margin-bottom: 20px;
}

.trading-highlights {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 24px;
  margin-top: 20px;
}

.highlight-item {
  background: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.highlight-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.12);
}

.highlight-item span {
  color: #64748b;
  font-size: 0.9rem;
}

.highlight-item h3 {
  font-size: 1.8rem;
  color: #1e293b;
  margin: 8px 0;
}

/* Product Performance Section */
.product-performance-section {
  margin: 32px 0;
}

.performance-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 24px;
  margin-top: 20px;
}

.performance-card {
  background: white;
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.performance-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.12);
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.badge {
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 0.8rem;
  font-weight: 500;
}

.badge.trending {
  background: rgba(79, 70, 229, 0.1);
  color: #4F46E5;
}

.badge.featured {
  background: rgba(6, 182, 212, 0.1);
  color: #06B6D4;
}

.metrics-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.metric {
  text-align: center;
}

.metric span {
  font-size: 0.9rem;
  color: #64748b;
}

.metric h4 {
  font-size: 1.4rem;
  color: #1e293b;
  margin: 8px 0;
}

/* Animation for new sections */
@keyframes slideInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.quick-overview-section,
.product-performance-section {
  animation: slideInUp 0.5s ease-out forwards;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .trading-highlights,
  .performance-cards {
    grid-template-columns: 1fr;
  }
  
  .metrics-row {
    gap: 16px;
  }
}

/* Enhanced Sales Analytics Header */
.sales-analytics-header {
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.header-main {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 24px;
}

.header-title h1 {
  font-size: 1.8rem;
  color: #1e293b;
  margin: 0 0 8px 0;
  font-weight: 600;
}

.header-title p {
  color: #64748b;
  margin: 0;
  font-size: 0.95rem;
}

.header-actions {
  display: flex;
  gap: 16px;
}

.date-range-picker {
  display: flex;
  align-items: center;
  gap: 8px;
  background: white;
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.date-range-picker select {
  border: none;
  color: #1e293b;
  font-size: 0.9rem;
  padding-right: 24px;
  cursor: pointer;
  background: transparent;
}

.quick-stats-bar {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 20px;
  margin-top: 24px;
  animation: slideUp 0.5s ease-out;
}

.stat-item {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  background: white;
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.stat-item:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.stat-icon {
  padding: 12px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stat-icon.orders {
  background: rgba(79, 70, 229, 0.1);
  color: #4F46E5;
}

.stat-icon.revenue {
  background: rgba(16, 185, 129, 0.1);
  color: #10B981;
}

.stat-icon.aov {
  background: rgba(245, 158, 11, 0.1);
  color: #F59E0B;
}

.stat-icon.active {
  background: rgba(6, 182, 212, 0.1);
  color: #06B6D4;
}

.stat-info {
  flex: 1;
}

.stat-info span {
  font-size: 0.85rem;
  color: #64748b;
  display: block;
  margin-bottom: 4px;
}

.stat-info h4 {
  font-size: 1.25rem;
  color: #1e293b;
  margin: 0 0 4px 0;
  font-weight: 600;
}

.stat-info .trend {
  font-size: 0.85rem;
  display: flex;
  align-items: center;
  gap: 4px;
}

.trend.positive {
  color: #10B981;
}

.trend.negative {
  color: #EF4444;
}

.trend.neutral {
  color: #F59E0B;
}

/* Animation */
@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .quick-stats-bar {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .header-main {
    flex-direction: column;
    gap: 16px;
  }
  
  .header-actions {
    width: 100%;
  }
  
  .date-range-picker {
    width: 100%;
    justify-content: center;
  }
  
  .quick-stats-bar {
    grid-template-columns: 1fr;
  }
}

.sales-executive-header {
  background: linear-gradient(135deg, #1e40af 0%, #3b82f6 100%);
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 24px;
  color: white;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.sales-executive-header .header-content {
  margin-bottom: 20px;
}

.sales-executive-header .header-content h1 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 8px;
  background: linear-gradient(to right, #fff, #e3f2fd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sales-executive-header .header-content p {
  font-size: 1.1rem;
  opacity: 0.9;
  margin: 0;
}

.sales-executive-header .quick-stats {
  display: flex;
  gap: 24px;
  margin-top: 16px;
  flex-wrap: wrap;
}

.sales-executive-header .stat-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  font-size: 0.95rem;
  transition: all 0.3s ease;
  backdrop-filter: blur(8px);
}

.sales-executive-header .stat-item:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
}

.sales-executive-header .stat-item svg {
  opacity: 0.9;
}

/* Animation for header content */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: fadeIn 0.6s ease-out forwards;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .sales-executive-header .quick-stats {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
  
  .sales-executive-header .header-content h1 {
    font-size: 2rem;
  }
}

@media (max-width: 768px) {
  .sales-executive-header {
    padding: 20px;
  }

  .sales-executive-header .quick-stats {
    grid-template-columns: 1fr;
  }

  .sales-executive-header .header-content h1 {
    font-size: 1.8rem;
  }

  .sales-executive-header .stat-item {
    width: 100%;
    justify-content: center;
  }
}

/* Add these new styles */
.channel-distribution-section {
  margin: 32px 0;
}

.channel-metrics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 24px;
  margin-bottom: 32px;
}

.channel-card {
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
}

.channel-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
}

.channel-card h3 {
  color: #1e293b;
  font-size: 1.2rem;
  margin-bottom: 16px;
}

.channel-value {
  font-size: 2.5rem;
  font-weight: 600;
  color: #4F46E5;
  margin: 16px 0;
}

.channel-growth, .channel-orders {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.9rem;
  margin-top: 8px;
}

.channel-growth.positive {
  color: #10B981;
}

.channel-growth.negative {
  color: #EF4444;
}

.channel-orders {
  color: #64748b;
}

.ecommerce-insights-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  gap: 24px;
  margin-top: 32px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .ecommerce-insights-grid {
    grid-template-columns: 1fr;
  }
  
  .channel-metrics-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .channel-metrics-grid {
    grid-template-columns: 1fr;
  }
}
 
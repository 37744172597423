.settings-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  backdrop-filter: blur(8px);
  animation: fadeIn 0.2s ease-out;
}

.settings-popup {
  background: white;
  border-radius: 20px;
  width: 95%;
  max-width: 1100px;
  max-height: 90vh;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  animation: slideUp 0.3s ease-out;
}

.settings-container {
  display: flex;
  height: calc(100vh - 70px);
  background: white;
  border-radius: var(--radius-md);
  overflow: hidden;
}

.settings-sidebar {
  width: 280px;
  background: var(--background-color);
  border-right: 1px solid var(--border-color);
  padding: var(--space-4);
}

.user-preview {
  padding: 2rem;
  text-align: center;
  border-bottom: 1px solid #e2e8f0;
}

.profile-image-container {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 0 auto 1rem;
}

.profile-image-container img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid white;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.image-upload-btn {
  position: absolute;
  right: 0;
  bottom: 0;
  background: #3b82f6;
  color: white;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
}

.image-upload-btn:hover {
  background: #2563eb;
  transform: scale(1.1);
}

.user-info h4 {
  font-size: 1.1rem;
  color: #1e293b;
  margin: 0 0 0.5rem;
}

.user-info span {
  color: #64748b;
  font-size: 0.9rem;
}

.tabs-container {
  padding: 1.5rem 1rem;
  flex: 1;
}

.tab-btn {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  width: 100%;
  padding: 0.875rem 1rem;
  border: none;
  background: none;
  color: #64748b;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s;
  text-align: left;
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
}

.tab-btn:hover {
  background: #f1f5f9;
  color: #0f172a;
}

.tab-btn.active {
  background: #e2e8f0;
  color: #0f172a;
  font-weight: 500;
}

.settings-content {
  flex: 1;
  overflow-y: auto;
  padding: var(--space-5);
}

.settings-section {
  margin-bottom: var(--space-6);
}

.settings-section h3 {
  font-size: 1.5rem;
  color: #0f172a;
  margin-bottom: 2rem;
  font-weight: 600;
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.form-group {
  margin-bottom: var(--space-4);
}

.form-group label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: #475569;
  margin-bottom: 0.5rem;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  font-size: 0.95rem;
  transition: all 0.2s;
  color: #1e293b;
}

.form-group input:focus,
.form-group select:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.form-group input:focus-visible,
.form-group select:focus-visible {
  outline: 2px solid var(--primary);
  outline-offset: 1px;
}

.notification-groups {
  display: grid;
  gap: 2rem;
}

.notification-group {
  background: #f8fafc;
  border-radius: 16px;
  padding: 1.5rem;
}

.notification-group h4 {
  font-size: 1.1rem;
  color: #0f172a;
  margin-bottom: 1rem;
}

.setting-items {
  display: grid;
  gap: 1rem;
}

.setting-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: white;
  border-radius: 12px;
  border: 1px solid #e2e8f0;
}

.setting-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.setting-info div span {
  display: block;
  font-weight: 500;
  color: #0f172a;
  margin-bottom: 0.25rem;
}

.setting-info div p {
  font-size: 0.875rem;
  color: #64748b;
  margin: 0;
}

.preferences-container {
  display: grid;
  gap: 2rem;
}

.preference-group {
  background: #f8fafc;
  border-radius: 16px;
  padding: 1.5rem;
}

.preference-group h4 {
  font-size: 1.1rem;
  color: #0f172a;
  margin-bottom: 1rem;
}

.theme-selector {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.theme-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s;
}

.theme-btn.active {
  background: #3b82f6;
  color: white;
  border-color: #3b82f6;
}

.settings-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding-top: 2rem;
  margin-top: 2rem;
  border-top: 1px solid #e2e8f0;
}

.cancel-btn {
  padding: 0.75rem 1.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  background: white;
  color: #64748b;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.cancel-btn:hover {
  background: #f8fafc;
  color: #0f172a;
}

.save-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background: #3b82f6;
  color: white;
  border: none;
  border-radius: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.save-btn:hover {
  background: #2563eb;
  transform: translateY(-1px);
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.settings-popup {
  animation: slideUp 0.3s ease-out;
}

.settings-popup-overlay {
  animation: fadeIn 0.2s ease-out;
}

@media (max-width: 768px) {
  .settings-container {
    flex-direction: column;
  }

  .settings-sidebar {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid var(--border-color);
  }

  .form-row {
    grid-template-columns: 1fr;
  }

  .settings-content {
    padding: var(--space-4);
  }
}

.settings-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;
  border-bottom: 1px solid #e2e8f0;
}

.settings-header h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #0f172a;
}

.close-btn {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: none;
  background: #f1f5f9;
  color: #64748b;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
}

.close-btn:hover {
  background: #e2e8f0;
  color: #0f172a;
  transform: rotate(90deg);
}

.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #cbd5e1;
  transition: .4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

input:checked + .slider {
  background-color: #3b82f6;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

.form-label {
  display: block;
  margin-bottom: var(--space-2);
  font-weight: 500;
  color: var(--text-primary);
}

.form-input {
  width: 100%;
  padding: var(--space-2) var(--space-3);
  border: 1px solid var(--border-color);
  border-radius: var(--radius-sm);
  transition: border-color 0.2s ease;
}

.form-input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.1);
} 